import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a0142948 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _6d7f921b = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _5ca0165e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _757d06c9 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _3702aaba = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _b0ccc0f2 = () => interopDefault(import('../pages/mobile/about/index.vue' /* webpackChunkName: "pages/mobile/about/index" */))
const _0b06e786 = () => interopDefault(import('../pages/mobile/artists/index.vue' /* webpackChunkName: "pages/mobile/artists/index" */))
const _ed5b75a2 = () => interopDefault(import('../pages/mobile/news/index.vue' /* webpackChunkName: "pages/mobile/news/index" */))
const _f279d79e = () => interopDefault(import('../pages/artists/opus/_id.vue' /* webpackChunkName: "pages/artists/opus/_id" */))
const _e7d5d924 = () => interopDefault(import('../pages/mobile/artists/_id.vue' /* webpackChunkName: "pages/mobile/artists/_id" */))
const _5c847152 = () => interopDefault(import('../pages/mobile/news/_id.vue' /* webpackChunkName: "pages/mobile/news/_id" */))
const _d71d417a = () => interopDefault(import('../pages/artists/_id.vue' /* webpackChunkName: "pages/artists/_id" */))
const _7ad013a2 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _0a285756 = () => interopDefault(import('../pages/mobile/_.vue' /* webpackChunkName: "pages/mobile/_" */))
const _41f70d2a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _a0142948,
    name: "about___ja"
  }, {
    path: "/artists",
    component: _6d7f921b,
    name: "artists___ja"
  }, {
    path: "/en",
    component: _5ca0165e,
    name: "index___en"
  }, {
    path: "/mobile",
    component: _757d06c9,
    name: "mobile___ja"
  }, {
    path: "/news",
    component: _3702aaba,
    name: "news___ja"
  }, {
    path: "/zh",
    component: _5ca0165e,
    name: "index___zh"
  }, {
    path: "/en/about",
    component: _a0142948,
    name: "about___en"
  }, {
    path: "/en/artists",
    component: _6d7f921b,
    name: "artists___en"
  }, {
    path: "/en/mobile",
    component: _757d06c9,
    name: "mobile___en"
  }, {
    path: "/en/news",
    component: _3702aaba,
    name: "news___en"
  }, {
    path: "/mobile/about",
    component: _b0ccc0f2,
    name: "mobile-about___ja"
  }, {
    path: "/mobile/artists",
    component: _0b06e786,
    name: "mobile-artists___ja"
  }, {
    path: "/mobile/news",
    component: _ed5b75a2,
    name: "mobile-news___ja"
  }, {
    path: "/zh/about",
    component: _a0142948,
    name: "about___zh"
  }, {
    path: "/zh/artists",
    component: _6d7f921b,
    name: "artists___zh"
  }, {
    path: "/zh/mobile",
    component: _757d06c9,
    name: "mobile___zh"
  }, {
    path: "/zh/news",
    component: _3702aaba,
    name: "news___zh"
  }, {
    path: "/en/mobile/about",
    component: _b0ccc0f2,
    name: "mobile-about___en"
  }, {
    path: "/en/mobile/artists",
    component: _0b06e786,
    name: "mobile-artists___en"
  }, {
    path: "/en/mobile/news",
    component: _ed5b75a2,
    name: "mobile-news___en"
  }, {
    path: "/zh/mobile/about",
    component: _b0ccc0f2,
    name: "mobile-about___zh"
  }, {
    path: "/zh/mobile/artists",
    component: _0b06e786,
    name: "mobile-artists___zh"
  }, {
    path: "/zh/mobile/news",
    component: _ed5b75a2,
    name: "mobile-news___zh"
  }, {
    path: "/en/artists/opus/:id?",
    component: _f279d79e,
    name: "artists-opus-id___en"
  }, {
    path: "/en/mobile/artists/:id",
    component: _e7d5d924,
    name: "mobile-artists-id___en"
  }, {
    path: "/en/mobile/news/:id",
    component: _5c847152,
    name: "mobile-news-id___en"
  }, {
    path: "/zh/artists/opus/:id?",
    component: _f279d79e,
    name: "artists-opus-id___zh"
  }, {
    path: "/zh/mobile/artists/:id",
    component: _e7d5d924,
    name: "mobile-artists-id___zh"
  }, {
    path: "/zh/mobile/news/:id",
    component: _5c847152,
    name: "mobile-news-id___zh"
  }, {
    path: "/artists/opus/:id?",
    component: _f279d79e,
    name: "artists-opus-id___ja"
  }, {
    path: "/en/artists/:id",
    component: _d71d417a,
    name: "artists-id___en"
  }, {
    path: "/en/news/:id",
    component: _7ad013a2,
    name: "news-id___en"
  }, {
    path: "/mobile/artists/:id",
    component: _e7d5d924,
    name: "mobile-artists-id___ja"
  }, {
    path: "/mobile/news/:id",
    component: _5c847152,
    name: "mobile-news-id___ja"
  }, {
    path: "/zh/artists/:id",
    component: _d71d417a,
    name: "artists-id___zh"
  }, {
    path: "/zh/news/:id",
    component: _7ad013a2,
    name: "news-id___zh"
  }, {
    path: "/en/mobile/*",
    component: _0a285756,
    name: "mobile-all___en"
  }, {
    path: "/zh/mobile/*",
    component: _0a285756,
    name: "mobile-all___zh"
  }, {
    path: "/artists/:id",
    component: _d71d417a,
    name: "artists-id___ja"
  }, {
    path: "/news/:id",
    component: _7ad013a2,
    name: "news-id___ja"
  }, {
    path: "/en/*",
    component: _41f70d2a,
    name: "all___en"
  }, {
    path: "/zh/*",
    component: _41f70d2a,
    name: "all___zh"
  }, {
    path: "/mobile/*",
    component: _0a285756,
    name: "mobile-all___ja"
  }, {
    path: "/",
    component: _5ca0165e,
    name: "index___ja"
  }, {
    path: "/*",
    component: _41f70d2a,
    name: "all___ja"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
